<template>
  <div class="testpage-container">
    <div class="test-loading-container" v-if="testState === states.loading">
      Загрузка теста...
    </div>
    <div class="test-ready-container" v-else-if="testState === states.ready">
      <div class="test-title">{{ openedTest.shortName }}</div>
      <div class="test-description justify-text" v-html="openedTest.description">
      </div>
      <div class="test-stage">{{ testStageName }}</div>
      <button class="regular-button" @click="testState = states.testing">
        Начать
      </button>
    </div>
    <div
      class="test-testing-container"
      v-else-if="testState === states.testing"
    >
      <LuscherQuestion
        v-if="openedTest.code === 'LusherTest'"
        @onQuestionsEnd="nextStage()"
      />
      <Question v-else @onQuestionsEnd="nextStage()" />
    </div>
    <div v-else-if="testState === states.done"></div>
    <div v-else-if="testState === states.none"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Question from "@/components/Question";
import LuscherQuestion from "@/components/LuscherQuestion";

export default {
  name: "TestPage",
  components: { Question, LuscherQuestion },
  data() {
    return {
      currentStage: -1,
      testState: null,
      states: Object.freeze({
        none: 0,
        loading: 1,
        ready: 2,
        testing: 3,
        done: 4,
      }),
      loading: {
        total: 0,
        current: 0,
        value: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "users/user",
      patient: "users/patient",
      appointment: "users/appointment",
      openedTest: "tests/openedTest",
      testSettings: "tests/testSettings",
      questions: "tests/questions",
      images: "tests/images",
    }),
    totalStages() {
      return this.testSettings?.totalCount;
    },
    testStageName() {
      return this.testSettings?.items?.[this.currentStage]?.name ?? "";
    },
  },
  methods: {
    ...mapActions({
      loadTestInfo: "tests/loadTestInfo",
      getPatient: "users/getPatient",
      createAppointment: "users/createAppointment",
      loadTestSettings: "tests/loadTestSettings",
      loadQuestions: "tests/loadQuestions",
      loadImage: "tests/loadImage",
      executeCalc: "tests/executeCalc",
    }),
    ...mapMutations({
      clearTestData: "tests/clearTestData",
    }),
    async loadTests() {
      this.currentStage = -1;
      await this.loadTestInfoData();
      this.currentStage = 0;
      await this.loadTestStage();
    },
    async loadTestInfoData() {
      this.clearTestData();
      this.testState = this.states.none;
      await this.loadTestInfo(this.$route.params.id);
      await this.getPatient(this.user);
      await this.createAppointment(this.patient);
      await this.loadTestSettings(this.openedTest.id);
    },
    async loadTestStage() {
      const index = this.currentStage;
      this.testState = this.states.loading;
      const test = this.testSettings.items[index];
      const q = await this.loadQuestions(test.id);
      this.loading.total = q.data.length;
      this.loading.current = 0;
      for (const question of q.data) {
        if (question["picture"]) await this.loadImage(question["picture"]);
        for (const answer of question.answers) {
          if (answer["picture"]) await this.loadImage(answer["picture"]);
        }
        this.loading.current++;
      }
      this.testState = this.states.ready;
    },

    nextStage() {
      if (this.currentStage >= this.totalStages - 1) {
        this.executeCalc(this.appointment.id);
        console.log("THAT'S ALL, FOLKS");
        this.testState = this.states.done;
        this.$router.push(
          `/result/${this.openedTest.id}/${this.appointment.id}`
        );
      } else {
        this.currentStage++;
        this.loadTestStage();
      }
    },
  },
  mounted() {
    console.log("TEST PAGE MOUNTED");
    if (this.user) {
      this.loadTests();
    }
  },
  watch: {
    user: function (newVal, oldVal) {
      console.log("USER CHANGED", newVal, oldVal);
      if (!this.patient) {
        this.loadTests();
      }
    },
  },
};
</script>

<style>
.testpage-container {
  width: 1000px;
  height: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden;

  margin: 0 auto;
  padding: 25px 30px;
}

.test-ready-container {
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}

.test-testing-container {
  height: 100%;

  display: flex;
}

.test-title {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;

  color: #a2a9c8;
}

.test-description {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #24243a;
}

.test-stage {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #24243a;
}

.test-loading-container {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: #24243a;
}
</style>