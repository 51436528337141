import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Admin from './Index.vue'
import vuetify from './plugins/vuetify';
import axios from "@/plugins/axios"
import tests from "@/store/tests";
import {api} from "@/store/api";
import {users} from "@/store/users";
import {router} from "@/plugins/router";
import VueToastify from "vue-toastify";

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueToastify);

axios.interceptors.response.use((response) => response, (error) => {
    Vue.$vToastify.error(error?.response)
    if (error?.response?.status === 401) {
        store.commit('api/logout');
    }
    throw error;
});

const navigation = {
    namespaced: true,
    state: () => ({
        drawer: false,
        selectedItem: -1
    }),
    mutations: {
        setNavDrawerMode(state, mode) {
            state.drawer = mode;
        },
        setNavSelectedItem(state, index) {
            state.selectedItem = index
        }
    }
}

const store = new Vuex.Store({
    modules: {
        navigation: navigation,
        api: api,
        tests: tests,
        users: users
    }
})

new Vue({
    vuetify,
    store,
    router,
    render: h => h(Admin)
}).$mount('#app')
