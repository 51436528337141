<template>
  <form class="login-page">
    <div class="login-form">
      <div class="login-header">Авторизация</div>
      <input
        class="login-input"
        v-model="username"
        :rules="rules"
        label="Логин"
        required
        type="username"
      />
      <input
        class="login-input"
        v-model="password"
        :rules="rules"
        label="Пароль"
        required
        type="password"
      />
      <div class="login-buttons">
        <button class="login-button" @click="authorize">Войти</button>
        <button class="login-button secondary" @click="$router.push('/')">
          Отмена
        </button>
      </div>
    </div>
    <div class="login-footer">
      <img src="../assets/SamGMUlogo.svg" />
      <div>СамГМУ</div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import Index from "../Index.vue";

export default {
  components: { Index },
  name: "Auth",
  data: () => ({
    username: "",
    password: "",
    min: 3,
    valid: true,
  }),
  methods: {
    async authorize() {
      let bodyFormData = new FormData();
      bodyFormData.append("grant_type", "password");
      bodyFormData.append("username", this.username);
      bodyFormData.append("password", this.password);
      this.logout();
      await this.login(bodyFormData);
      this.$router.push("/");
    },
    ...mapMutations({
      logout: "api/logout",
    }),
    ...mapActions({
      login: "api/login",
    }),
  },
  computed: {
    ...mapGetters({
      user: "users/user",
    }),
    rules() {
      const rules = [];
      {
        const rule = (v) =>
          (v || "").length >= this.min ||
          `Минимальное количество символов: ${this.min}`;

        rules.push(rule);
      }

      return rules;
    },
  },
};
</script>

<style scoped>
.login-page {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;

  gap: 30px;
}

.login-header {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 33px;

  color: #a2a9c8;
}

.login-input {
  background: #f9fbfd;

  border: 1px solid rgba(168, 184, 239, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  width: 360px;

  padding: 10px;

  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;

  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #5068a5;
}

.login-buttons {
  display: flex;
  flex-direction: row-reverse;
  width: 360px;
  gap: 20px;
}

.login-button {
  background: #277adb;
  border-radius: 10px;

  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #f9fbfd;

  width: 360px;
  height: 40px;

  transition-duration: 0.2s;
}

.secondary {
  background: #f9fbfd;

  border: 1px solid #277adb;
  box-sizing: border-box;

  color: #277adb;
}

.login-button:hover {
  box-shadow: 1px 7px 20px rgba(119, 142, 239, 0.3);

  transition-duration: 0.2s;
}

.login-button:active {
  box-shadow: none;

  transition-duration: 0.2s;
}

.login-footer {
  flex-shrink: 0;
  padding: 50px;
  text-align: center;

  font-family: "Montserrat";
  font-size: 11px;
  line-height: 13px;

  color: #24243a;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
</style>
