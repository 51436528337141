<template>
  <div class="results-card">
    <div class="results-card-header">Тест Люшера</div>
    <div class="results-card-value-container">
      <div class="results-card-value-header">Ваш результат составляет</div>
      <div class="results-card-value">{{ testResultData[0].value }}</div>
      <div class="results-card-value-subtext">баллов</div>
      <div class="results-card-value-subtext-secondary">
        и интерпретируется как:
      </div>
    </div>
    <div class="results-card-interpretation justify-text">{{ interpretation }}</div>
    <div class="results-card-results-link-container">
      <button class="regular-button" @click="$router.push('/relax')">Пройти релаксацию</button>
    </div>
    <div>
      <a class="secondary-link" href="relax-vr:">Запустить Desktop-релаксацию</a>
    </div>
  </div>
</template>

<style>
.results-card {
  width: 420px;

  background: linear-gradient(180deg, #f1f3fa 0%, #f5f7fe 100%);
  border: 1px solid #ffffff;
  box-sizing: border-box;

  box-shadow: 0px 4px 22px #ccd0df;
  border-radius: 12px;

  padding: 15px 25px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 15px;
}

.results-card-header {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #a2a9c8;
}

.results-card-value-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.results-card-value-header {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: #24243a;
}

.results-card-value {
  width: 100px;
  height: 100px;
  background-color: #eaecf4;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 64px;
  text-align: center;

  color: #24243a;
}

.results-card-value-subtext {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: #24243a;
}

.results-card-value-subtext-secondary {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #a2a9c8;
}

.results-card-interpretation {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #24243a;
}

.results-card-results-link-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.relax-link-text {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;

  color: #277adb;
}

.secondary-link {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
    font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  color: #277adb;
}
</style>

<script>
export default {
  name: "LuscherResult",
  data() {
    return {};
  },
  props: {
    testResultData: Array,
  },
  computed: {
    score() {
      const rawScore = this.testResultData[0].value;

      switch (true) {
        case rawScore >= 0 && rawScore <= 2:
          return 1;
        case rawScore >= 4 && rawScore <= 6:
          return 2;
        case rawScore >= 8 && rawScore <= 12:
          return 3;
        case rawScore >= 14 && rawScore <= 20:
          return 4;
        case rawScore >= 22 && rawScore <= 26:
          return 5;
        case rawScore >= 28 && rawScore <= 32:
          return 6;
        case rawScore == 32:
          return 7;
      }

      return 0;
    },
    interpretation() {
      switch (this.score) {
        case 1:
        case 2:
          return (
            "Отсутствие непродуктивной нервно-психической напряженности. " +
            "Активность, разнообразность действий, коэффициент полезного действия высокий. " +
            "Эмоциональный настрой -оптимистичный. Развит волевой самоконтроль. " +
            "При этом воля не является орудием подавления какой-то части личности, " +
            "она лишь согласует различные, но не противоречивые желания. " +
            "При наличии соответствующей мотивации способность интенсивно работать длительное время. " +
            "В экстремальных ситуациях эффективная мобилизация сосредоточенность на выполнении задачи."
          );
        case 3:
          return (
            "Незначительный уровень непродуктивной нервно-психической напряженности. " +
            "Преобладает установка на активную деятельность. " +
            "Ресурсов достаточно для более-менее регулярных вспышек сверхактивности и напряжения, " +
            "недоступных большинству других людей. " +
            "В условиях мотивированной (интересной) деятельности отсутствуют трудности с оперативным и " +
            "долговременным запоминанием и воспроизведением информации. " +
            "Отсутствие стремления к острым ощущениям. Успешный выход из стрессовых ситуаций."
          );
        case 4:
          return (
            "Cредний уровень непродуктивной нервно-психической напряженности. " +
            "Выполнение обязанностей в пределах средних сложившихся требований. " +
            "В привычной обстановке переход от работы к отдыху и обратно, " +
            "от одного вида деятельности к другому без существенных затруднений. " +
            "В случае необходимости способность преодолевать усталость волевым усилием, " +
            "однако после этого в делах и самочувствии просматривается длинный «шлейф» " +
            "сниженной работоспособности. Для сохранения здоровья и работоспособности требуется " +
            "относительно четкое субъективное разделение времени работы и отдыха."
          );
        case 5:
          return (
            "Повышенный уровень непродуктивной нервно-психической напряженности. " +
            "Потенциал целесообразной активности очень невысок. " +
            "Постоянно действующий волевой контроль усиливает переутомление. " +
            "Длительная интенсивная работа скорее всего будет дорого стоить в нервно-психическом плане. " +
            "При этом она будет очень неравноценной по производительности и качеству в разные периоды времени. " +
            "Общий эмоциональный фон характеризуется повышенной возбудимостью, тревожностью, неуверенностью. " +
            "В стрессовой ситуации вероятен срыв деятельности и поведения."
          );
        case 6:
        case 7:
          return (
            "Выраженная непродуктивная нервно-психическая напряженность. " +
            "Повышенная утомляемость. Внимание легко отвлекается посторонними вещами, " +
            "при этом характерно «застраивание» на эмоциональных переживаниях. " +
            "Отсутствие устойчивой иерархии мотивов приводит к не целенаправленности действий. " +
            "Настроение в кратчайшее время может колебаться между экзальтацией и подавленностью, " +
            "возбуждением и бессилием. В эмоциональном состоянии преобладают тревога, " +
            "предчувствие неприятностей, бессилие и отсутствие желания что-либо делать. " +
            "В экстремальной ситуации ненадежен."
          );
      }
      return "Ошибка интерпретации результатов тестирования!";
    },
  },
};
</script>