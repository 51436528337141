<template>
  <div class="main-context">
    <sidebar v-show="!$route.meta.hideSidebar"></sidebar>
    <router-view></router-view>
    <div class="samsmu-logo">
      <img src="../assets/SamGMUlogo.svg" />
      <div>СамГМУ</div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import FooterComponent from "@/components/FooterComponent";
import Sidebar from "./Sidebar.vue";

export default {
  name: "MainContext",
  components: { FooterComponent, Sidebar },
  data() {
    return {
      mini: true,
    };
  },
  computed: {
    ...mapState({
      token: (state) => state.api.token,
      drawer: (state) => state.navigation.drawer,
      authorization: (state) => state.api.authorization,
      waitingForResponse: (state) => state.api.waitingForResponse,
    }),
  },
  methods: {
    ...mapActions({
      getUserInfo: "users/getUserInfo",
    }),
    ...mapMutations({
      setNavDrawerMode: "navigation/setNavDrawerMode",
      setNavSelectedItem: "navigation/setNavSelectedItem",
    }),
    requestUpdate() {
      console.log("REQUEST UPDATE");
    },
  },
  async beforeMount() {
    await this.getUserInfo();
  },
  mounted() {
    this.requestUpdate();
  },
};
</script>

<style scoped>
.main-context {
  position: absolute;

  width: 100vw;
  height: 100%;

  display: flex;
}

.samsmu-logo {
  position: absolute;
  right: 30px;
  top: 25px;

  font-family: "Montserrat";
  font-size: 11px;
  line-height: 13px;

  color: #24243a;

  display: flex;
  align-items: center;
  gap: 5px;
}
</style>