import axios from "@/plugins/axios";

export const users = {
    namespaced: true,
    state: () => ({
        user: null,
        patient: null,
        appointment: null
    }),
    getters: {
        user: state => state.user,
        patient: state => state.patient,
        appointment: state => state.appointment
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
        setPatient(state, patient) {
            state.patient = patient
        },
        setAppointment(state, app){
            state.appointment = app
        }
    },
    actions: {
        getUserInfo({commit}) {
            return new Promise((resolve, reject) => {
                axios.get(`Users/UserInfo`)
                    .then(resp => {
                        commit('setUser', resp.data)
                        console.log(resp)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getPatient({commit}, user) {
            console.log("GET PATIENT", user)
            const request = {
                PageRequest: {
                    PageSize: 2,
                    Page: 1
                },
                Sorts: null,
                Filters: [{
                    "Key": "IsDefault",
                    "Search": "False",
                    "Type": 1,
                    "AndSearch": [{
                        "Key": "All",
                        "Search": `${user.surname} ${user.firstName} ${user.middleName}`,
                        "Type": 1,
                        "AndSearch": []
                    }]
                }]
            }

            return new Promise((resolve, reject) => {
                axios.post(`Patients/Search`, request)
                    .then(resp => {
                        if (resp.data.items.length === 0) {
                            axios.post(`/Patients/Default`, {id: 0})
                                .then(r => {
                                    commit('setPatient', r.data)
                                    resolve(r)
                                })
                        } else {
                            commit('setPatient', resp.data.items[0])
                            resolve(resp)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        createAppointment({commit}, patient) {
            const req = {
                "Id": null,
                "PatientId": patient.id,
                "DoctorId": 0,
                "AppointmentDate": null,
                "AppointmentNumber": null,
                "IsSystem": false,
                "AppointmentCreateDateUtc": null
            };
            return new Promise((resolve, reject) => {
                axios.post(`Appointments`, req)
                    .then(resp => {
                        console.log(resp)
                        const req1 = {
                            "Id": null,
                            "AppointmentId": resp.data.id,
                            "Description": "WebPsychoDiags",
                            "CreateDateUtc": "0001-01-01T00:00:00",
                            "Estimate": null
                        };
                        return new Promise(() => {
                            axios.post('Test/Execution/Appointment', req1)
                                .then(resp1 => {
                                    console.log(resp1)
                                    commit('setAppointment', resp1.data)
                                    resolve(resp1)
                                })
                                .catch(err => {
                                    console.log(err)
                                    reject(err)
                                })
                        })
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
    }

}
