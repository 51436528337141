<template>
  <div class="question-container">
    <div class="progress-bar">
      <div class="progress-bar-progress" :style="progressWidth"></div>
    </div>
    <div class="text bold">{{ question }}</div>
    <div class="text justify-text">{{ etc }}</div>
    <div class="answers-container">
      <div class="answers-grid">
        <button
          v-for="(answer, index) in answers"
          :disabled="selectedAnswers.includes(answer)"
          class="answer-button"
          :key="index"
          @click="sendAnswer(answer)"
        >
          <img :src="getAnswerImage(answer)" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "LuscherQuestion",
  data() {
    return {
      selectedQuestion: 0,
      selectedAnswers: [],
    };
  },
  computed: {
    ...mapGetters({
      questions: "tests/questions",
      image: "tests/image",
      appointment: "users/appointment",
    }),
    question() {
      return this.questions?.[this.selectedQuestion]?.description;
    },
    etc() {
      return this.questions?.[this.selectedQuestion]?.ect;
    },
    answers() {
      return this.shuffle(this.questions?.[this.selectedQuestion]?.answers);
    },
    totalQuestions() {
      return this.questions?.length ?? 0;
    },
    questionImage() {
      const imageId = this.questions?.[this.selectedQuestion]?.picture;
      if (!imageId) return;
      return this.image(imageId).image;
    },
    progressWidth() {
      return {
        width: `${(this.selectedAnswers.length / this.answers.length) * 100}%`,
      };
    },
  },
  methods: {
    ...mapActions({
      send: "tests/sendAnswer",
    }),
    sendAnswer(answer) {
      const payload = {
        answerId: answer.id,
        CreateDateUtc: null,
        Estimate: "00:00:00",
        TestExecutionId: this.appointment.id,
      };

      this.send(payload);

      this.selectedAnswers.push(answer);
      console.log(answer.description);
      if (this.selectedAnswers.length >= this.answers.length) {
        this.selectedAnswers = [];
        if (this.selectedQuestion >= this.totalQuestions - 1) {
          this.$emit("onQuestionsEnd");
        } else this.selectedQuestion++;
      }
    },
    getAnswerImage(answer) {
      return this.image(answer.picture).image;
    },
    shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (currentIndex != 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
};
</script>

<style scoped>
.question-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 50px 0 0 0;
  gap: 50px;
}

.progress-bar {
  height: 8px;
  width: 100%;
  border-radius: 4px;

  background: linear-gradient(0deg, #d5d9ea, #d5d9ea), #24243a;
}

.progress-bar-progress {
  height: 8px;
  background: #277adb;
  border-radius: 4px;

  transition-duration: 0.2s;
}

.text {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #24243a;
}

.bold {
  font-weight: 600;
}

.answers-container {
  position: relative;
  height: 100%;

  display: flex;
  justify-content: center;
}

.answers-grid {
  min-height: 150px;
  max-height: 100%;

  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;

  position: absolute;

  aspect-ratio: 2 / 1;
}

.answer-button {
  width: calc(25% - 20px);
  height: calc(50% - 20px);

  display: flex;

  background: linear-gradient(0deg, #f7f8fd, #f7f8fd), #fafafa;
  border: 1px solid #ffffff;
  box-sizing: border-box;

  box-shadow: 0px 0px 18px rgba(58, 71, 149, 0.3);
  border-radius: 20px;

  padding: 10px;

  margin: 10px;

  transition-duration: 0.2s;
}

.answer-button:disabled {
  opacity: 0;

  transition-duration: 0.2s;
}

.answer-button img {
  width: 100%;
  height: 100%;

  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 16px;

  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
</style>