<template>
  <div class="homepage-container">
    <div class="homepage-header">
      <h1 class="homepage-header-text">Добро пожаловать!</h1>
      <p class="homepage-header-description">
        Предлагаем Вам пройти диагностику и узнать Ваш уровень тревожности и
        стресса.
      </p>
    </div>
    <div class="homepage-tests-container">
      <!-- <div class="homepage-test-card">
        <h1 class="homepage-test-header">Тест Люшера</h1>
        <p class="homepage-test-description">
          Диагностика уровня стресса - цветовой тест Люшера
        </p>
        <button
          class="regular-button"
          @click="() => this.$router.push(`tests/${11976}`)"
        >
          Запустить
        </button>
      </div>
      <div class="homepage-test-card">
        <h1 class="homepage-test-header">Тест по шкале Бека</h1>
        <p class="homepage-test-description">
          Если у Вас есть нарушения цветовосприятия, рекомендуется пройти
          текстовый тест
        </p>
        <button
          class="regular-button"
          @click="() => this.$router.push(`tests/${11975}`)"
        >
          Запустить
        </button>
      </div> -->
      <div class="homepage-test-card">
        <h1 class="homepage-test-header">
          Есть ли у Вас нарушения цветовосприятия?
        </h1>
        <!-- <p class="homepage-test-description">
          Диагностика уровня стресса - цветовой тест Люшера
        </p> -->
        <button
          class="regular-button"
          @click="() => this.$router.push(`tests/${luscherId}`)"
        >
          Нет
        </button>
        <button
          class="secondary-link"
          @click="() => this.$router.push(`tests/${beckId}`)"
        >
          Да
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "RelaxHomepage",
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      fetchTests: "tests/fetchTests",
      fetchAllTests: "tests/fetchAllTests",
    }),
    async loadTests() {
      await this.fetchAllTests();
    },
  },
  computed: {
    ...mapState({
      allTestsList: (state) => state.tests.allTestsList,
    }),
    beckId() {
      return this.allTestsList.find((t) => t.code == "BekScale").id;
    },
    luscherId() {
      return this.allTestsList.find((t) => t.code == "LusherTest").id;
    },
  },
  mounted() {
    this.loadTests();
  },
};
</script>

<style scoped>
.homepage-container {
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 50px;
}

.homepage-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 25px;
}

.homepage-header-text {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;

  color: #a2a9c8;
}

.homepage-header-description {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  color: #24243a;
}

.homepage-tests-container {
  display: flex;
  gap: 30px;
}

.homepage-test-card {
  background: linear-gradient(92.04deg, #f9fcff 17.38%, #f5f6ff 91.35%);
  box-shadow: 0px 0px 50px rgba(133, 162, 236, 0.3);
  border-radius: 16px;

  padding: 30px;
  margin: 20px;

  max-width: 400px;

  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  gap: 15px;
}

.homepage-test-header {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #277adb;
}

.homepage-test-description {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #24243a;
}
</style>
