<template>
  <div class="results-page-container">
    <div class="results-page-test-header">{{ testInfo.shortName }}</div>
    <div v-if="this.loadState === this.states.loading">
      <!-- <div class="text">
        Загрузка результатов теста. Осталось попыток:
        {{ this.remainingAttempts }}
      </div> -->
    </div>
    <div class="results-container" v-else-if="this.loadState === this.states.done">
      <div class="results-warning">
        <img src="../assets/Exclamation.svg" />
        <div class="text justify-text">
          Полученные результаты носят рекомендательный характер и не являются
          полноценной психологической диагностикой. Для более достоверного
          обследования необходимо обратиться к специалисту.
        </div>
      </div>
      <BeckResult
        v-if="testInfo.code === 'BekScale'"
        :test-result-data="resultData"
      />
      <LuscherResult
        v-else-if="testInfo.code === 'LusherTest'"
        :test-result-data="resultData"
      />
      <div v-else>{{ this.resultData }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BeckResult from "@/components/resultComponents/BeckResult";
import LuscherResult from "@/components/resultComponents/LuscherResult";

export default {
  name: "ResultPage",
  components: { BeckResult, LuscherResult },
  data() {
    return {
      testInfo: {},
      resultData: [],
      states: Object.freeze({
        none: 0,
        loading: 1,
        done: 2,
        fail: 3,
      }),
      loadState: null,
      remainingAttempts: 30,
      loadAttemptTimeout: 1000,
      mounted: true,
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      getResult: "tests/getResult",
      loadTestInfo: "tests/loadTestInfo",
    }),
    async loadResult() {
      this.loadState = this.states.loading;

      const testInfoRes = await this.loadTestInfo(
        this.$route.params.testTypeId
      );
      this.testInfo = testInfoRes.data;

      while (this.remainingAttempts > 0 && this.mounted) {
        const data = await this.getResult(this.$route.params.sessionId);
        if (data.length > 0) {
          if ("value" in data[0]) {
            this.resultData = data;
            this.loadState = this.states.done;
            console.log("test results:", data);
            return;
          }
        }

        await new Promise((r) => setTimeout(r, this.loadAttemptTimeout));

        this.remainingAttempts--;
      }

      this.loadState = this.states.fail;
    },
  },
  mounted() {
    this.loadResult();
  },
  beforeDestroy() {
    this.mounted = false;
  },
};
</script>

<style>
.results-page-container {
  width: 1000px;
  margin: 0 auto;
  padding: 25px 30px;

  display: flex;
  flex-direction: column;
  gap: 40px;
}

.results-page-test-header {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;

  color: #277adb;
}

.text {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #24243a;
}

.results-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  gap: 40px;
}

.results-warning {
  display: flex;
  gap: 15px;
}
</style>