<template>
  <div class="app">
    <MainContext v-if="isLoggedIn" />
  </div>
</template>

<script>
import Auth from "@/components/Auth";
import MainContext from "@/components/MainContext";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Index",
  components: {
    MainContext,
    Auth,
  },
  methods: {
    ...mapActions({
      initToken: "api/initToken",
      login: "api/login",
    }),
    authDefault() {
      let bodyFormData = new FormData();
      bodyFormData.append("grant_type", "password");
      const user = process.env.VUE_APP_USER;
      const password = process.env.VUE_APP_PASSWORD;
      bodyFormData.append("username", user);
      bodyFormData.append("password", password);
      this.login(bodyFormData);
    },
  },
  computed: {
    ...mapState({
      waitingForResponse: (state) => state.api.waitingForResponse,
    }),
    ...mapGetters({
      isLoggedIn: "api/isLoggedIn",
      isLoading: "api/isLoading",
    }),
  },
  beforeMount() {
    this.initToken();
    if (!this.isLoggedIn) {
      this.authDefault();
    } 
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");

.titleWrapFix {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  word-break: normal;
  hyphens: auto;
}

body {
  background-color: #f3f5fa;
}

.justify-text {
  text-align: justify;
}

.regular-button {
  background: #277adb;
  border-radius: 20px;

  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #f9fbfd;

  min-width: 140px;
  height: 30px;

  padding: 0 20px;

  transition-duration: 0.2s;
}

.regular-button:hover {
  box-shadow: 1px 7px 20px rgba(119, 142, 239, 0.3);

  transition-duration: 0.2s;
}

.regular-button:active {
  background: #f9fbfd;
  color: #277adb;
  border: 1px solid #277adb;
  box-sizing: border-box;

  box-shadow: none;

  transition-duration: 0.2s;
}

.regular-button:disabled {
  background: #a2a9c8;
  box-shadow: none;

  transition-duration: 0.2s;
}
</style>
