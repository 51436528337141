import VueRouter from "vue-router";
import Home from "@/pages/Home";
import TestList from "@/pages/TestList";
import TestPage from "@/pages/TestPage";
import ResultPage from "@/pages/ResultPage"
import RelaxPlayerPage from "@/pages/RelaxPlayerPage"
import Auth from "@/components/Auth"

export const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: Home,
            name: "Домой",
            icon: "mdi-home"
        },
        {
            path: '/tests',
            component: TestList,
            name: "Тесты",
            icon: "mdi-bullseye-arrow",
        },
        {
            path: '/tests/:id',
            name: "Тест",
            component: TestPage,
            meta: {
                hideNavigation: true,
                ignored: true,
                backButton: true
            }
        },
        {
            path: '/result/:testTypeId/:sessionId',
            name: 'Результат теста',
            component: ResultPage,
            meta: {
                hideNavigation: true,
                ignored: true,
                backButton: true
            }
        },
        {
            path: '/relax',
            component: RelaxPlayerPage,
            name: 'Релаксация',
        },
        {
            path: '/auth',
            component: Auth,
            name: 'Авторизация',
            meta: {
                hideSidebar: true
            }
        }
    ]
})
