<template>
  <div class="results-card">
    <div class="results-card-header">Шкала Бека</div>
    <div class="results-card-value-container">
      <div class="results-card-value-header">Ваш результат составляет</div>
      <div class="results-card-value">{{ testResultData[0].value }}</div>
      <div class="results-card-value-subtext">баллов</div>
      <div class="results-card-value-subtext-secondary">
        и интерпретируется как:
      </div>
    </div>
    <div class="results-card-interpretation justify-text">{{ interpretation }}</div>
    <div class="results-card-results-link-container">
      <!-- <div class="relax-link-text">Пройти релаксацию</div> -->
      <button class="regular-button" @click="$router.push('/relax')">Пройти релаксацию</button>
    </div>
    <div>
      <a class="secondary-link" href="relax-vr:">Запустить Desktop-релаксацию</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BeckResult",
  data() {
    return {};
  },
  props: {
    testResultData: Array,
  },
  computed: {
    interpretation() {
      const score = this.testResultData[0].value;
      switch (true) {
        case score >= 0 && score <= 9:
          return (
            "Отсутствие депрессивных симптомов. " +
            "Плохое настроение возникает в рамках нормы и " +
            "является адекватной ситуацией на возникающие обстоятельства. " +
            "Главное – не усугублять свое состояние в такие моменты. " +
            "Необходимо вовремя переключаться с негативных мыслей на нейтральную деятельность."
          );
        case score >= 10 && score <= 15:
          return (
            "Легкая депрессия (субдепрессия). Не является заболеванием. " +
            "Возникает на фоне усталости и негативных переживаний. " +
            "Может возникать подавленность, снижается работоспособность. " +
            "В подобных ситуациях необходимо соблюдать режим дня и питания, " +
            "снизить влияние вредных привычек на жизнь и стараться вести более подвижный образ жизни."
          );
        case score >= 16 && score <= 19:
          return (
            "Умеренная депрессия. Частое погружение в меланхолию, грусть. " +
            "Раздражительность по незначительным поводам. Общая физическая вялость, " +
            "нехватка сил и энергии. Избегание общения, снижение самооценки, появление чувства вины. " +
            "Могут появляться проблемы со здоровьем: нарушение работы ЖКТ, повышение давления, " +
            "появление судорог, болей в спине, суставах, сердце."
          );
        case score >= 20 && score <= 29:
          return (
            "Выраженная депрессия средней тяжести. Критическое состояние. " +
            "Пропадает интерес к окружающему миру, все действия выполняются «на автомате». " +
            "Страдает не только психологическая сфера, но и физическая – появление или обострение заболеваний."
          );
        case score >= 30 && score <= 63:
          return (
            "Тяжелая депрессия. Нарушается адаптация во всех сферах жизни – " +
            "отношения, работа, здоровье и т.д. Ощущается чувство безысходности, неполноценности. " +
            "Появляется склонность к насилию и аутоагресии. Резкие перепады настроения. Изменение веса."
          );
      }
      return "Ошибка интерпретации результатов тестирования!";
    },
    needHelp() {
      const score = this.testResultData[0].value;
      return score >= 16;
    },
  },
};
</script>