import axios from "@/plugins/axios";

export const api = {
    namespaced: true,
    state: () => ({
        token: null,
        status: '',
    }),
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        isLoading: state => state.status === 'loading',
    },
    mutations: {
        api_request(state) {
            state.status = 'loading'
        },
        api_success(state) {
            state.status = 'success'
        },
        api_error(state) {
            state.status = 'error'
        },
        auth_success(state, token) {
            console.log(token)
            state.status = 'success'
            state.token = token
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            localStorage.removeItem('token')
            axios.defaults.headers.common.Authorization = null
        },
        setToken(state, token) {
            if (token) {
                state.token = token;
                localStorage.setItem('token', token)
                axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
            }
        },
        loadToken(state) {
            if (!state.token) {
                state.token = localStorage.getItem('token') || '';
            }
        }
    },
    actions: {
        initToken({commit, state}) {
            return new Promise((resolve) => {
                commit('loadToken');
                commit('setToken', state.token);
                resolve();
            })
        },
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('api_request');
                axios({
                    method: 'post',
                    url: `AccessService/Account/token/`,
                    data: user,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'x-api-auth-key': 'userSkipCompany'
                    }
                })
                    .then(resp => {
                        console.log(resp.data)
                        const token = resp.data.access_token
                        commit('setToken', token);
                        commit('auth_success', token)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
    }
}
