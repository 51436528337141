<template>
  <div>
    <v-card class="pa-0 ma-0 " color="white" flat tile>
      <v-card-title class="d-flex justify-center titleWrapFix">
        Список доступных тестов
      </v-card-title>
      <v-divider/>
      <v-skeleton-loader
          v-if="loading"
          type="list-item@4"
      ></v-skeleton-loader>
      <v-card-text v-else class="px-md-16 pa-0">
        <div v-show="showPaginator" class="text-center">
          <v-pagination
              :length="pages"
              :value="selectedPage"
              @input="openPage"
              @next="openPage(selectedPage+1)"
              @previous="openPage(selectedPage-1)"
          ></v-pagination>
        </div>
        <v-list
            flat>
          <v-list-item
              v-for="(test) in testList"
              :key="test.id"
              class="nav-item"
          >
            <v-list-item-icon v-show="!$vuetify.breakpoint.mobile">
              <v-icon>mdi-pen</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="titleWrapFix">
              {{ test.shortName }}
            </v-list-item-title>
            <v-btn icon @click="showDescription(test)">
              <v-icon>mdi-information</v-icon>
            </v-btn>
            <v-btn icon @click="openTest(test)">
              <v-icon>mdi-play-circle</v-icon>
            </v-btn>
          </v-list-item>
        </v-list>

      </v-card-text>
    </v-card>

    <v-dialog
        v-model="dialog"
        :retain-focus="false"
        width="500"
    >
      <v-card>
        <v-card-title class="titleWrapFix">
          {{ selectedTest.shortName }}
        </v-card-title>
        <v-card-subtitle>{{ selectedTest.name }}</v-card-subtitle>
        <v-card-text>
          {{ selectedTest.description }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState,} from "vuex";

export default {
  name: "TestList",
  data() {
    return {
      dialog: false,
      selectedTest: 'd',
      loading: false
    }
  },
  methods: {
    ...mapActions({
      fetchTests: 'tests/fetchTests',
      loadTestInfo: 'tests/loadTestInfo'
    }),
    openTest(test) {
      this.$router.push(`tests/${test.id}`)
    },
    openPage(number = 1) {
      console.log("open page", number)
      let requestInProgress = true;
      setTimeout(() => {
        if (requestInProgress)
          this.loading = true;
      }, 1000)
      this.fetchTests(number).finally(() => {
        requestInProgress = false;
        this.loading = false
      })
    },
    showDescription(selectedTest) {
      this.selectedTest = selectedTest;
      this.dialog = true
    }
  },
  computed: {
    ...mapState({
      testList: state => state.tests.testList,
      selectedPage: state => state.tests.selectedPage,
    }),
    ...mapGetters({
      pages: 'tests/pages',
      showPaginator: 'tests/showPaginator'
    })
  },
  mounted() {
    this.openPage();
  }
}
</script>

<style scoped>
.nav-item {
  margin: 5px;
  border-radius: 4px;
  border: #D5DAE8 1px solid;
  background: #F3F4FC;
}

</style>
