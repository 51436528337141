<template>
  <div class="sidebar">
    <div class="sidebar-main">
      <div class="sidebar-user">{{ this.firstName }}</div>
      <button
        v-if="this.userName === 'test06'"
        class="regular-button"
        @click="$router.push('/auth')"
      >
        Авторизация
      </button>
      <div class="sidebar-menu">
        <button
          class="sidebar-menu-item sidebar-menu-item-test"
          @click="() => this.$router.push(`/`)"
        >
          Тесты
        </button>
      </div>
    </div>
    <div class="sidebar-footer">
      <button
        v-if="this.userName !== 'test06'"
        class="regular-button"
        @click="logout()"
      >
        Выход
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "users/user",
    }),
    firstName() {
      return this.user?.firstName;
    },
    userName() {
      return this.user?.userName;
    },
  },
  methods: {
    ...mapMutations({
      clearToken: "api/logout",
    }),
    logout() {
      this.clearToken();
      document.location.href = "/";
    },
  },
};
</script>

<style>
.sidebar {
  width: 200px;

  display: flex;
  flex-direction: column;

  background: linear-gradient(105.79deg, #f4f7fe 11.88%, #e1e6f3 96.16%);

  box-shadow: 0px 0px 40px rgba(84, 109, 139, 0.37);
}

.sidebar-main {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  padding: 25px;

  gap: 50px;
}

.sidebar-user {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: #277adb;

  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.sidebar-user::before {
  display: inline-block;
  content: "";
  width: 30px;
  height: 30px;
  mask-image: url(../assets/User.svg);
  background-color: #277adb;
  flex-shrink: 0;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.sidebar-menu-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: #277adb;

  display: flex;
  gap: 10px;

  border-bottom: 1px solid #d0daf7;
  padding: 10px 0;

  transition-duration: 0.2s;
}

.sidebar-menu-item:hover {
  color: #24243a;

  transition-duration: 0.2s;
}

.sidebar-menu-item-test:hover::before {
  background-color: #24243a;

  transition-duration: 0.2s;
}

.sidebar-menu-item-test::before {
  display: inline-block;
  content: "";
  width: 24px;
  height: 24px;
  mask-image: url(../assets/Test.svg);
  background-color: #277adb;
  flex-shrink: 0;

  transition-duration: 0.2s;
}

.sidebar-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 25px;
}
</style>