import axios from "@/plugins/axios";

export const types = {
    openedTestStage: {
        id: 1,
        testTypeId: 1,
        name: "",
        calculationId: 1,
        unit: "",
        maxValue: 265,
        allowedMultipleAnswers: false
    },
    openedQuestion: {
        answers: [
            {
                description: "",
                value: 5,
                testQuestionId: 1,
                id: 2
            }
        ],
        id: 1,
        testId: 1,
        description: ""
    }
}

export default {
    namespaced: true,
    state: () => ({
        testList: [],
        allTestsList: [],
        selectedPage: 1,
        totalTests: 0,
        testsPerPage: 4,
        openedTest: {
            shortName: "",
            description: "",
            name: "",
            code: "",
            id: -1
        },
        testSettings: {
            totalCount: 0
        },
        questions: null,
        images: [],
        selectedStage: 0,
    }),
    getters: {
        pages: state => Math.ceil(state.totalTests / state.testsPerPage),
        showPaginator: state => state.totalTests > state.testsPerPage,
        testSettings: state => state.testSettings,
        openedTest: state => state.openedTest,
        questions: state => state.questions,
        images: state => state.images,
        image: state => id => {
            return state.images.find(i => i.id === id)
        },
        currentStage: state => state.testSettings?.items?.[state.selectedStage]
    },
    mutations: {
        setTests(state, newTests) {
            console.log("SET TESTS", newTests.items)
            state.testList = newTests.items;
            state.totalTests = newTests.totalCount;
            state.selectedPage = newTests.page;
        },
        setAllTests(state, tests) {
            console.log("SET ALL TESTS", tests.items)
            state.allTestsList = tests.items;
        },
        setTest(state, newTest) {
            console.log("SET TEST", newTest)
            state.openedTest = newTest;
        },
        setTestSettings(state, newSettings) {
            state.testSettings = newSettings;
        },
        setQuestions(state, newQuestions) {
            state.questions = newQuestions;
        },
        storeImage(state, { id, image }) {
            console.log("storing image", id)
            state.images.push({ id, image })
        },
        clearTestData(state) {
            state.questions = null
            state.testSettings = []
        },
        selectStage(state, index) {
            state.selectedStage = index
        }
    },
    actions: {
        fetchTests({ commit, state }, page = 1) {
            console.log(page)
            return new Promise((resolve, reject) => {
                axios.get(`Test/Type?pageSize=${state.testsPerPage}&page=${page}`)
                    .then(resp => {
                        console.log(resp)
                        commit('setTests', { ...resp.data, page });
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        fetchAllTests({ dispatch, commit, state }) {
            dispatch("fetchTests").then(() => {
                return new Promise((resolve, reject) => {
                    axios.get(`Test/Type?pageSize=${state.totalTests}&page=${1}`)
                        .then(resp => {
                            console.log(resp)
                            commit('setAllTests', { ...resp.data });
                            resolve(resp)
                        })
                        .catch(err => {
                            console.log(err)
                            reject(err)
                        })
                })
            })
        },
        loadTestInfo({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.get(`Test/Type/${id}`)
                    .then(resp => {
                        console.log(resp)
                        commit('setTest', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        loadTestSettings({ commit }, id) {
            console.log("LOAD TEST SETTINGS", id)
            return new Promise((resolve, reject) => {
                axios.get(`Test/Settings/Search?pageSize=1000&page=1&testTypeId=${id}`)
                    .then(resp => {
                        console.log(resp)
                        commit('setTestSettings', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        loadQuestions({ commit }, id) {
            console.log("LOAD QUESTION", id)
            return new Promise((resolve, reject) => {
                axios.get(`Test/Question/Get/${id}`)
                    .then(resp => {
                        console.log(resp)
                        commit('setQuestions', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        loadImage({ commit, state }, id) {
            console.log("LOAD IMAGE", id)
            const req = {
                id: id,
                key: "Screening"
            }

            return new Promise((resolve, reject) => {
                if (state.images.map(s => s.id).includes(id)) {
                    console.log(`${id} already loaded`)
                    return resolve(state.images[id])
                }
                axios.post(`FileStorage/Storage`, req, {
                    responseType: 'blob',
                })
                    .then(resp => {
                        console.log(resp)
                        const reader = new FileReader()
                        reader.onloadend = () => {
                            commit('storeImage', { id: id, image: reader.result })
                            resolve(reader.result)
                        }
                        reader.onerror = reject
                        reader.readAsDataURL(resp.data)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        sendAnswer({ commit }, answer) {
            return new Promise((resolve, reject) => {
                axios.post(`Test/Result`, answer)
                    .then(res => {
                        console.log(res.data)
                        resolve(res.data)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        executeCalc({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.put(`TestExecutionCalc/${id}`)
                    .then(res => {
                        console.log(res.data)
                        resolve(res.data)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        },
        getResult({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.get(`TestExecutionCalc/${id}`)
                    .then(res => {
                        console.log(res.data)
                        resolve(res.data)
                    })
                    .catch(err => {
                        console.log(err)
                        reject(err)
                    })
            })
        }
    }
}
