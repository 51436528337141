<template>
  <!-- <div>
    <v-card-actions>
      <v-progress-linear
        :value="(selectedQuestion / totalQuestions) * 100"
      ></v-progress-linear>
    </v-card-actions>

    <v-btn
      :disabled="!backButtonEnabled"
      class="my-5"
      @click="previousQuestion()"
    >
      Назад
    </v-btn>

    <v-card-text>
      Вопрос

      <v-card-title class="titleWrapFix" style="min-height: 150px">{{
        question
      }}</v-card-title>
      <v-img :src="questionImage"></v-img>
      Ответы
      <v-list :min-width="compact ? 300 : 600">
        <v-list-item
          v-for="(answer, index) in answers"
          :key="index"
          class="elevation-1"
          dense
          @click="sendAnswer(answer)"
        >
          <v-list-item-icon v-show="!compact">
            {{ index + 1 }}
          </v-list-item-icon>

          <v-img v-if="answer.picture" :src="getAnswerImage(answer)"></v-img>

          <v-list-item-content>
            {{ answer.description }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </div> -->
  <div class="question-container">
    <div class="progress-bar">
      <div class="progress-bar-progress" :style="progressWidth"></div>
    </div>
    <button
      class="regular-button"
      :disabled="!backButtonEnabled"
      @click="previousQuestion()"
    >
      Назад
    </button>
    <div class="text" v-html="question"></div>
    <div class="answers-container">
      <button
        class="answer-button text"
        v-for="(answer, index) in answers"
        :key="index"
        @click="sendAnswer(answer)"
      >
        {{ answer.description }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Question",
  data() {
    return {
      selectedQuestion: 0,
    };
  },
  computed: {
    ...mapGetters({
      questions: "tests/questions",
      image: "tests/image",
      appointment: "users/appointment",
    }),
    question() {
      return this.questions?.[this.selectedQuestion]?.description;
    },
    answers() {
      const unordreredAnswers =
        this.questions?.[this.selectedQuestion]?.answers;
      const ordered = unordreredAnswers.sort((a, b) => a.order - b.order);
      return ordered;
    },
    totalQuestions() {
      return this.questions?.length ?? 0;
    },
    questionImage() {
      const imageId = this.questions?.[this.selectedQuestion]?.picture;
      if (!imageId) return;
      return this.image(imageId).image;
    },
    compact() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    backButtonEnabled() {
      return this.selectedQuestion > 0;
    },
    progressWidth() {
      return {
        width: `${(this.selectedQuestion / this.totalQuestions) * 100}%`,
      };
    },
  },
  methods: {
    ...mapActions({
      send: "tests/sendAnswer",
    }),
    sendAnswer(answer) {
      const payload = {
        answerId: answer.id,
        CreateDateUtc: null,
        Estimate: "00:00:00",
        TestExecutionId: this.appointment.id,
      };

      this.send(payload);

      if (this.selectedQuestion >= this.totalQuestions - 1) {
        this.$emit("onQuestionsEnd");
      } else this.selectedQuestion++;
    },
    getAnswerImage(answer) {
      return this.image(answer.picture)?.image;
    },
    previousQuestion() {
      this.selectedQuestion--;
    },
  },
};
</script>

<style scoped>
.question-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 50px 0;
  gap: 50px;
}

.progress-bar {
  height: 8px;
  width: 100%;
  border-radius: 4px;

  background: linear-gradient(0deg, #d5d9ea, #d5d9ea), #24243a;
}

.progress-bar-progress {
  height: 8px;
  background: #277adb;
  border-radius: 4px;

  transition-duration: 0.2s;
}

.text {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #24243a;
}

.answers-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;

  padding: 0 150px;
}

.answer-button {
  display: flex;
  text-align: start;
  align-items: center;
  gap: 40px;
}

.answer-button::before {
  content: "";
  display: inline-block;
  min-width: 16px;
  min-height: 16px;
  border-radius: 8px;
  border: solid #24243a 1px;
  box-sizing: border-box;

  background: #FAFAFA;

  transition-duration: 0.2s;
}

.answer-button:hover::before {
  background: radial-gradient(
    circle at center,
    #24243a 0,
    #24243a 35%,
    #FAFAFA 45%,
    #FAFAFA 100%
  );

  transition-duration: 0.2s;
}
</style>