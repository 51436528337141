<template>
  <v-footer fixed padless app>
    <v-container class="d-flex justify-center">
      <v-img
          alt="SamSmu Logo"
          class="shrink mr-2"
          contain
          height="24"
          src="../assets/SamSmuLogo.png"
          width="24"
      />
      СамГМУ | Нейрореабилитация
    </v-container>
  </v-footer>
</template>s
<script>
export default {
  name: 'FooterComponent'
}
</script>
