<template>
  <!-- <div>
    <v-card flat style="margin: 0 10vw">
      <v-responsive :aspect-ratio="16 / 9">
        <iframe class="webgl_player" src="https://dev.relax.rehab.smuit.ru/relax20220311/"></iframe>
      </v-responsive>
      <v-btn class="ma-2" @click="webglFullscreen()">Полноэкранный режим</v-btn>
    </v-card>
  </div> -->
  <div class="relax-player-page-container">
    <!-- <div class="relax-instructions" v-if="showInstruction">
      {{ instruction }}
      <button class="regular-button" @click="state++">Далее</button>
    </div> -->
    <div class="relax-player-container">
      <iframe class="webgl_player" :src="build"></iframe>
      <button class="regular-button" @click="webglFullscreen()">Полный экран</button>
      <button id="redirect-button" @click="$router.push('/')"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RelaxPlayerPage",
  data() {
    return {
      build: process.env.VUE_APP_WEBGL_URL,
      state: 0,
      instructions: [
        "Практика релаксации позволит Вам чувствовать себя спокойнее, сосредоточеннее, " +
          "поможет Вам стать менее подверженным стрессу. " +
          "Для коррекции и профилактики стрессовых состояний воспользуйтесь программой релаксации. " +
          "Для полного погружения используйте наушники для прослушивания релаксации",
        "На экране перед Вами будет сцена пейзажа Голубого озера. " +
          "Внимательно слушайте текст. В процессе релаксации Вам необходимо взаимодействовать с " +
          "объектами на экране. Объекты будут подсвечиваться",
      ],
    };
  },
  computed: {
    showInstruction() {
      return this.state < this.instructions.length;
    },
    instruction() {
      return this.instructions[this.state];
    },
  },
  methods: {
    webglFullscreen() {
      const player = document.getElementsByClassName("webgl_player")[0];
      player.requestFullscreen();
    },
  },
  mounted() {
    var eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {

      if (e.data === "myevent" || e.message === "myevent")
        document.location = "/"
    });
  },
};
</script>

<style>
.webgl_player {
  border: none;

  width: 100%;
  height: 100%;
}

.relax-player-page-container {
  width: 1000px;
  height: 650px;
  margin: auto auto;
  padding: 25px 30px;

  display: flex;
  justify-content: center;
  align-items: stretch;
}

.relax-instructions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  gap: 30px;

  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  color: #24243a;
}

.relax-player-container {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 25px;
}
</style>