<template>
  <RelaxHomepage/>
</template>

<script>
import { mapGetters } from "vuex";
import RelaxHomepage from "@/components/RelaxHomepage";

export default {
  name: "Home",
  components: { RelaxHomepage },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "users/user",
    }),
    username() {
      return this.user?.firstName;
    },
  },
};
</script>